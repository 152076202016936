<template>
    <confirmDelete :closeConfirm="closeConfirm" :isShown="isShown" :id="assessmentId" />
    <div :style="{ overflowY: isShown ? 'hidden' : 'visible' }" class="assessments-container">
        <div class="btn-section">
            <router-link to="/assessments/add"><button class="w-full h-15 py-2 rounded-lg border-none bg-[#53B9AB] font-medium text-white shadow-md hover:opacity-80">Add +</button></router-link>
        </div>
        <div class="w-[90%] relative flex items-center justify-between">
            <div class="fixed top-0 left-0 w-full h-full" v-if="isOpen" @click="toggleFilter"></div>
            <input
                placeholder="Search Test"
                class="!shadow-m !text-sm !py-1 !px-3 !border !border-[1.5px] !rounded-md !h-[3rem] w-[20%] !border-[#e7e7e9]"
                type="text"
                id="searchInput"
                name="searchInput"
                v-model="searchText"
                required
            />
            <button
                @click="toggleFilter()"
                class="bg-white border-[#e7e7e9] !border !border-[1.5px] text-gray-700 inline-flex items-center justify-center text-m rounded min-h-[30px] px-4 py-[10px] font-m focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
                Filters <font-awesome-icon class="ml-2" :icon="['fas', 'caret-down']" />
            </button>
            <div v-if="isOpen" class="p-4 absolute w-[20%] shadow-xl bg-[#fff] overflow-y-scroll z-10 top-[102%] right-0">
                <div>
                    <h3 class="text-[16px] mb-2" style="font-weight: 600">Per category:</h3>
                    <label v-for="(category, index) in categories" :key="index" class="checkbox-label">
                        <input type="checkbox" :value="category" v-model="selectedCategories" @change="filterAssessments" class="checkbox-input" />
                        <span class="checkbox-text">{{ category }}</span>
                    </label>
                </div>
                <div>
                    <h3 class="text-[16px] mt-4 mb-2" style="font-weight: 600">Test Duration:</h3>
                    <label v-for="(duration, index) in testDurations" :key="index" class="checkbox-label">
                        <input type="checkbox" :value="duration" v-model="selectedDurations" @change="SearchByDuration" class="checkbox-input" />
                        <span class="checkbox-text text-[5px]">{{ duration }}</span>
                    </label>
                </div>
                <div>
                    <h3 class="text-[16px] mt-4 mb-2" style="font-weight: 600">Test Focus:</h3>
                    <label v-for="(focus, index) in testFucus" :key="index" class="checkbox-label">
                        <input type="checkbox" :value="focus" v-model="selectedFocus" @change="filterAssessments" class="checkbox-input" />
                        <span class="checkbox-text">{{ focus }}</span>
                    </label>
                </div>
            </div>
        </div>
        <table v-if="this.allAssessments">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Category</th>
                    <th class="actions">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="assessment in filteredAssessments" :key="assessment._id">
                    <td class="title-case">{{ assessment.name }}</td>
                    <td class="">{{ getFirstPhrase(assessment.description_test) }}</td>
                    <td class="">{{ assessment.category }}</td>
                    <td class="actions w-1/5">
                        <router-link :to="`/assessments/edit/${assessment._id}`"> <font-awesome-icon class="icons w-4 mr-1 h-4 p-2 update text-white" :icon="['fas', 'pencil']" /></router-link>
                        <router-link :to="`/assessments/${assessment._id}`"> <font-awesome-icon class="icons w-4 info mr-1 h-4 p-2 text-white" :icon="['fas', 'circle-info']" /></router-link>
                        <font-awesome-icon @click="openConfirmDelete(assessment._id)" class="icons w-4 h-4 bg-red-500 p-2 text-white" :icon="['fas', 'trash']" />
                    </td>
                </tr>
            </tbody>
        </table>
        <table v-else>
            <thead>
                <tr class="loading-tr">
                    <th></th>
                </tr>
            </thead>
            <tbody class="loading-body">
                <LoaderComponentBlue />
            </tbody>
        </table>
    </div>
</template>
<script>
import axios from "axios";
import { useStore } from "../store/index";
import confirmDelete from "../components/ConfirmDeleteAssessment.vue";
import LoaderComponentBlue from "../components/LoaderComponentBlue";

export default {
    name: "AssessmentDashboard",
    components: { confirmDelete, LoaderComponentBlue },
    data() {
        return {
            assessmentId: "",
            selected: [true, false],
            filteredAssessments: this.allAssessments,
            isShown: false,
            isOpen: false,
            TopAssessement: [],
            personalityTests: [],
            yourAssessment: [],
            allAssessments: [],
            searchPayload: "",
            hardSkills: [],
            softSkills: [],
            psychometrics: [],
            categories: ["Hard Skills", "Interpersonal Skills", "Behavioral Skills", "Personality"],
            selectedCategories: [],
            searchText: "",
            testDurations: ["Up to 8min", "8min to 12min", "12min to 15min"],
            selectedDurations: [],
            testFucus: ["Verbal Reasoning", "Aptitude", "Numerical Reasoning"],
            selectedFocus: [],
            companies: ["KPMG", "PWC", "Workforce", "Adobe"],
            selectedCompanies: [],
            filterContainerTop: 11,
        };
    },
    watch: {
        allAssessments: {
            handler(newAssessments) {
                this.filteredAssessments = newAssessments.filter((assessment) => {
                    return assessment.name.toLowerCase().includes(this.searchPayload.toLowerCase());
                });
                this.isLoading = false;
            },
            immediate: true, // This ensures the handler runs immediately on component mount
        },
        selectedCategories: "filterAssessments",
        searchText: "searchAssessment",
        selectedDurations: "SearchByDuration",
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        getFirstPhrase(description) {
            // Split the string into an array of words
            let words = description?.split(/\s+/);

            // Extract the first 200 words
            let first200Words = words?.slice(0, 6)?.join(" ");
            return first200Words + "...";
        },
        async fetchAllAssessments() {
            axios
                .get("https://server.go-platform.com/AssessmentTest/hardSkills", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.hardSkills = res.data.hardSkills;
                    this.Store.setPremium(res.data.premium);
                    this.allAssessments = [...this.allAssessments, ...this.hardSkills];
                    console.log({ length: this.hardSkills.length });
                });
            axios
                .get("https://server.go-platform.com/AssessmentTest/psychometrics", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.psychometrics = res.data;
                    this.allAssessments = [...this.allAssessments, ...this.psychometrics];
                    console.log({ psychometrics: this.psychometrics });
                });
            axios
                .get("https://server.go-platform.com/AssessmentTest/softSkills", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.softSkills = res.data;
                    this.allAssessments = [...this.allAssessments, ...this.softSkills];
                    console.log({ softSkills: this.softSkills });
                });
            axios
                .get("https://server.go-platform.com/AssessmentTest/topAssessments", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.TopAssessement = res.data;
                    this.allAssessments = [...this.allAssessments, ...this.TopAssessement];
                });
            axios
                .get("https://server.go-platform.com/AssessmentTest/personality", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.personalityTests = res.data;
                    this.allAssessments = [...this.allAssessments, ...this.personalityTests];
                    console.log("All assessments: " + this.allAssessments[0]);
                });
        },
        filterAssessments() {
            this.filteredAssessments = this.allAssessments.filter((assessment) => {
                const matchesSearch = assessment.name.toLowerCase().includes(this.searchText.toLowerCase());
                const mappedCategories = this.selectedCategories.map((category) => {
                    switch (category) {
                        case "Hard Skills":
                            return "Hard Skills";
                        case "Interpersonal Skills":
                            return "Soft Skills";
                        case "Behavioral Skills":
                            return "Psychometrics";
                        case "Personality":
                            return "Personality";
                        default:
                            return category;
                    }
                });
                const matchesCategory = mappedCategories.length === 0 || mappedCategories.includes(assessment.category);
                const matchesDuration = this.checkDuration(assessment);
                const matchesFocus = this.selectedFocus.length === 0 || this.selectedFocus.some((focus) => assessment.name.includes(focus));
                const matchesCompany = this.selectedCompanies.length === 0 || this.selectedCompanies.some((company) => assessment.name.includes(company));

                return matchesSearch && matchesCategory && matchesDuration && matchesFocus && matchesCompany;
            });
        },
        searchAssessment() {
            this.filteredAssessments = this.allAssessments.filter((assessment) => {
                const matchesSearch = assessment.name.toLowerCase().includes(this.searchText.toLowerCase());
                const matchesCategory = this.selectedCategories.length === 0 || this.selectedCategories.includes(assessment.category);
                const matchesDuration = this.checkDuration(assessment);
                const matchesFocus = this.selectedFocus.length === 0 || this.selectedFocus.some((focus) => assessment.name.includes(focus));
                return matchesSearch && matchesCategory && matchesDuration && matchesFocus;
            });
        },
        SearchByDuration() {
            this.filteredAssessments = this.allAssessments.filter((assessment) => {
                const matchesSearch = assessment.name.toLowerCase().includes(this.searchText.toLowerCase());
                const matchesCategory = this.selectedCategories.length === 0 || this.selectedCategories.includes(assessment.category);
                const matchesDuration = this.checkDuration(assessment);
                const matchesFocus = this.selectedFocus.length === 0 || this.selectedFocus.some((focus) => assessment.name.includes(focus));
                return matchesSearch && matchesCategory && matchesDuration && matchesFocus;
            });
        },
        checkDuration(assessment) {
            const duration = assessment.questions_nbr > 25 ? parseInt((20 * 35) / 60) : parseInt((assessment.questions_nbr * 35) / 60);
            return (
                this.selectedDurations.length === 0 ||
                this.selectedDurations.some((selectedDuration) => {
                    if (selectedDuration === "Up to 8min" && duration <= 8) {
                        return true;
                    } else if (selectedDuration === "8min to 12min" && duration > 8 && duration <= 12) {
                        return true;
                    } else if (selectedDuration === "12min to 15min" && duration > 12 && duration <= 15) {
                        return true;
                    }
                    return false;
                })
            );
        },
        openConfirmDelete(id) {
            this.assessmentId = id;
            this.isShown = true;
        },

        closeConfirm() {
            this.isShown = false;
        },
        toggleFilter() {
            this.isOpen = !this.isOpen;
        },
    },
    computed: {
        articles() {
            return this.Store.getArticlesAdmin;
        },
        loadingArticles() {
            return this.Store.getLoadingArticles;
        },
    },
    mounted() {
        this.fetchAllAssessments();
    },
};
</script>
<style scoped>
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.checkbox-label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    /* Adjust spacing between checkboxes */
    margin-bottom: 10px;
}

.checkbox-input {
    margin-right: 5px;
    /* Adjust spacing between checkbox and label */
    background-color: #2196f3;
}

.checkbox-text {
    margin-left: 5px;
    /* Adjust spacing between checkbox and label */
    font-size: 15px;
    font-weight: 400;
}

.assessments-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}

.btn-section {
    width: 90%;
    display: flex;
    align-items: flex-end;
}

table {
    width: 90%;
    border: 1px solid #ddd;
}

thead {
    background: #2196f3;
    color: #fff;
}

tbody {
    background: #fff;
}

.loading-tr {
    background: #2196f3;
    height: 40px;
}

.loading-body {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

th {
    padding: 8px 10px;
    text-align: start;
    border-bottom: 1px solid #ddd;
    font-weight: 600;
    font-size: 16px;
}

.actions {
    text-align: center;
}

td {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
}

.icons {
    cursor: pointer;

    &:hover {
        opacity: 0.85;
    }
}

.update {
    background: #5bb98b;
}

.info {
    background: #2196f3;
}

a {
    width: 15%;
}

.title-case {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
}
</style>
