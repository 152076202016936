<template>
    <confirmDeleteCategory :fetchCategories="getCategories" :closeConfirm="closeConfirm" :isShown="isShown" :id="categoryId" />
    <div :style="{ overflowY: isShown ? 'hidden' : 'visible' }" class="categories-container">
        <div class="btn-section">
            <router-link to="/addCategory"><button>Add +</button></router-link>
        </div>
        <table v-if="!this.isLoading">
            <thead>
                <tr>
                    <th>Name</th>
                    <th class="actions">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="category in categoriesList" :key="category._id">
                    <td class="w-1.5/2">{{ category.category }}</td>
                    <td class="actions w-0.5/5">
                        <router-link :to="`/updateCategory/${category._id}`"> <font-awesome-icon class="icons w-4 mr-1 h-4 p-2 update text-white" :icon="['fas', 'pencil']" /></router-link>
                        <font-awesome-icon @click="openConfirmDeleteCategory(category._id)" class="icons w-4 h-4 bg-red-500 p-2 text-white" :icon="['fas', 'trash']" />
                    </td>
                </tr>
            </tbody>
        </table>
        <table v-else>
            <thead>
                <tr class="loading-tr">
                    <th></th>
                </tr>
            </thead>
            <tbody class="loading-body">
                <LoaderComponentBlue />
            </tbody>
        </table>
    </div>
</template>
<script>
import confirmDeleteCategory from "../components/ConfirmDeleteCategory.vue";
import LoaderComponentBlue from "../components/LoaderComponentBlue";
import axios from "axios";

export default {
    name: "adminCategories",
    components: { confirmDeleteCategory, LoaderComponentBlue },
    data() {
        return {
            isShown: false,
            categoryId: "",
            categoriesList: [],
            isLoading: true,
        };
    },
    methods: {
        getCategories() {
            axios
                .get("https://server.go-platform.com/categories/", {
                    withCredentials: true,
                })
                .then((response) => (this.categoriesList = response.data.categories))
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => (this.isLoading = false));
        },
        openConfirmDeleteCategory(id) {
            this.categoryId = id;
            this.isShown = true;
        },

        closeConfirm() {
            this.isShown = false;
        },
    },
    computed: {},
    mounted() {
        this.getCategories();
    },
};
</script>
<style scoped>
.categories-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
}

.btn-section {
    width: 60%;
    display: flex;
    align-items: flex-end;
}

table {
    width: 60%;
    border: 1px solid #ddd;
}

thead {
    background: #2196f3;
    color: #fff;
}

.loading-tr {
    background: #2196f3;
    height: 40px;
}

.loading-body {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

th {
    padding: 8px 10px;
    text-align: start;
    border-bottom: 1px solid #ddd;
    font-weight: 600;
    font-size: 16px;
}

.actions {
    text-align: center;
}

td {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
}

tbody {
    background: #fff;
}

.icons {
    cursor: pointer;

    &:hover {
        opacity: 0.85;
    }
}

.update {
    background: #5bb98b;
}

.info {
    background: #2196f3;
}

button {
    width: 100%;
    height: 40px;
    padding: 0.5rem;
    border-radius: 10px;
    border: none;
    background: #2196f3;
    font-weight: 500;
    color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.85;
    }
}

a {
    width: 15%;
}

.title-case {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
}
</style>
